import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageIntro from "../components/pageIntro"
import { Content } from "../components/layouts"

const ThankYouPage = () => (
  <Layout solidHeader>
    <SEO title="Services" />

    <PageIntro>
      <h2>Enquriy</h2>
    </PageIntro>
    <Content>
      <h3>Thank you for your submission!</h3>
    </Content>
  </Layout>
)

export default ThankYouPage
